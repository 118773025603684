import React, { Component } from "react";
import { scroller } from "react-scroll";
import { navigate } from "gatsby";

const click = (to, mobile, callback) => {
  const pathname = window.location.pathname;
  if (pathname !== "/") {
    navigate("/#" + to);
    setTimeout(() => {
      scroller.scrollTo(to, {
        duration: 500,
        delay: mobile ? 500 : 0,
        smooth: true,
        offset: -50,
      });
    }, 500);
  } else {
    scroller.scrollTo(to, {
      duration: 500,
      delay: mobile ? 500 : 0,
      smooth: true,
      offset: -50,
    });
  }
  if (callback) {
    callback();
  }
};

export default ({ label, mobile, onClick, style, to }) => (
  <div
    className={
      mobile ? "nav-bar-menu-mobile-link" : "nav-bar-link nav-bar-link-desktop"
    }
    style={style}
    onClick={() => click(to, mobile, onClick)}
  >
    {label}
  </div>
);
