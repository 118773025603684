import React, { Component } from "react";
import { Paragraph, Small, SubTitle } from "../components/text";

import "./fixed_bottom_bar.sass";

const contacts = [
  {
    title: "Sweden",
    number: +46728853800,
    number_string: "+46 728 853 800",
  },
  {
    title: "Norway",
    number: +4740004388,
    number_string: "+47 4000 43 88",
  },
  {
    title: "Denmark",
    number: +4553614075,
    number_string: "+45 536 140 75",
  },
  {
    title: "Finland",
    number: +358207309892,
    number_string: "+358 207 309 892",
  },
];

export default class FixedBottomBar extends Component {
  state = {
    open: false,
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;
    return (
      <div
        className={
          "fixed-bottom-bar" +
          (open ? " fixed-bottom-bar-open" : " fixed-bottom-bar-closed")
        }
        onClick={this.toggleOpen}
      >
        <Paragraph bright center className="fixed-bottom-bar-title">
          Contact our 24/7 EMERGENCY RESPONSE
        </Paragraph>
        <Arrow />
        <Contacts open={open} />
      </div>
    );
  }
}

const Contacts = ({ open }) => (
  <div className="emergency-contacts">
    {contacts.map((contact) => (
      <Paragraph center key={contact.number}>
        {contact.title}:
        <a href={`tel:${contact.number}`}>{contact.number_string}</a>
      </Paragraph>
    ))}
  </div>
);

const Arrow = () => (
  <div className="fixed-bottom-bar-arrow">
    <span className="fixed-bottom-bar-arrow-down"></span>
  </div>
);
