import React, { useState, useEffect } from "react";
import "./FullWidthImage.sass";

const FullWidthImage = ({ image, height, style, dim, children, parallax }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("resize", windowHeightHandler);
    windowHeightHandler();
    return () => {
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("resize", windowHeightHandler);
    };
  }, [windowHeight]);

  const scrollHandler = () => {
    const newScrollPosition = window.scrollY;
    if (newScrollPosition > windowHeight) {
      return;
    }
    setScrollPosition(newScrollPosition);
  };

  const windowHeightHandler = () => {
    setWindowHeight(window.innerHeight);
  };

  let header_parallax = {};

  if (parallax) {
    header_parallax = {
      transform: `translateY(${scrollPosition / 3}px)`,
      opacity: 1 - scrollPosition / (windowHeight * (parallax / 100)) || 1,
    };
  }

  return (
    <div
      className="full-width-image-container"
      style={{
        backgroundImage: `url(${image?.childImageSharp?.fluid.src})`,
        height,
        ...style,
      }}
    >
      {dim && <div className="dimmer" style={{ opacity: dim }}></div>}

      {children && <div style={header_parallax}>{children}</div>}
    </div>
  );
};

export default FullWidthImage;
