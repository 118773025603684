import React, { Component } from "react";
import { Link } from "gatsby";
import ScrollLink from "./scroll_link";
import MenuMobile from "./menu_mobile";
import MenuMobileButton from "./menu_mobile_button";

import "./navbar.sass";
import logo from "../../../img/logo.svg";

const links = [
  // {
  //   title: 'About us',
  //   path: '/about'
  // },
  {
    title: "Our Services",
    scroll_element: "services",
  },
  {
    title: "News",
    scroll_element: "news",
  },
  {
    title: "The Team",
    path: "/team",
  },
  {
    title: "Contact",
    path: "/contact",
  },
  {
    title: "Career",
    path: "/career",
  },
];

const mobile_break_point = 680;

export default class Navbar extends Component {
  state = {
    menu_open: false,
    is_mobile: false,
  };

  componentDidMount = () => {
    this.checkDeviceWidth();
    window.addEventListener("resize", this.checkDeviceWidth);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.checkDeviceWidth);
  };

  checkDeviceWidth = () => {
    const switched_to_mobile =
      window.innerWidth < mobile_break_point && !this.state.is_mobile;
    const switched_to_not_mobile =
      window.innerWidth >= mobile_break_point && this.state.is_mobile;
    if (switched_to_mobile) {
      this.setState({ is_mobile: true });
    } else if (switched_to_not_mobile) {
      this.setState({ is_mobile: false });
    }
  };

  toggleMobileMenu = () => {
    this.setState({ menu_open: !this.state.menu_open });
  };

  render() {
    const { menu_open, is_mobile } = this.state;

    return (
      <nav className="nav-bar">
        <div className="nav-bar-content">
          <Link to="/" className="nav-bar-logo">
            <img src={logo} alt="Wesmans" />
          </Link>
          {is_mobile && (
            <MenuMobile
              links={links}
              open={menu_open}
              onClick={this.toggleMobileMenu}
            />
          )}
          {is_mobile && (
            <MenuMobileButton
              open={menu_open}
              onClick={this.toggleMobileMenu}
            />
          )}
          {!is_mobile && (
            <div
              className={"nav-bar-links" + (menu_open ? " open" : " closed")}
            >
              {links.map((link) => {
                if (link.path) {
                  return (
                    <Link
                      className="nav-bar-link nav-bar-link-desktop"
                      to={link.path}
                      key={link.path + link.scroll_element}
                    >
                      {link.title}
                    </Link>
                  );
                } else if (link.scroll_element) {
                  return (
                    <ScrollLink
                      to={link.scroll_element}
                      label={link.title}
                      key={link.path + link.scroll_element}
                    />
                  );
                }
              })}
            </div>
          )}
        </div>
      </nav>
    );
  }
}

// <div
//   className={ 'navbar-menu-button' + (this.state.menu_open ? ' active' : ' inactive') }
//   onClick={ this.toggle }
// >
//   <span></span>
//   <span></span>
//   <span></span>
// </div>
