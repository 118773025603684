import React from "react";
import Layout from "../components/Layout";

import "./page.sass";

export default ({ children, diffBg, noBottomPadding }) => (
  <Layout>
    <div
      className={"page-content" + (noBottomPadding ? " no-bottom-padding" : "")}
    >
      {children}
    </div>
  </Layout>
);
