import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Navbar from "./navbar/navbar";
import FixedBottomBar from "../fixed_bottom_bar";

import "../../styles/all.sass";
import "../../styles/text.sass";

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet title="Wesmans" />
    <Navbar />
    <FixedBottomBar />
    {children}
  </div>
);

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default TemplateWrapper;
